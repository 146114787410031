<template>
  <v-card class="mt-4 pa-1">
    <v-card-title class="flex-nowrap">
      <SchoolSubjectChip :school-subject="schoolSubject" />
      <v-spacer />
      <v-btn
        v-if="canWriteWeekReport"
        icon
        @click="$emit('edit-week-report')"
      >
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="text">
      <LessonTextField
        label="Bericht"
        :value="text"
      />
    </v-card-text>
  </v-card>
</template>


<script>
import SchoolSubjectChip from '@/components/school-subject-chip'
import LessonTextField from '@/components/lesson-text-field'

export default {
  name: 'WeekReportCard',
  components: {
    SchoolSubjectChip,
    LessonTextField
  },
  props: {
    schoolSubject: {
      type: Object,
      required: true
    },
    weekReport: Object
  },
  computed: {
    canWriteWeekReport() {
      return this.$store.getters['canWriteWeekReport']
    },
    text() {
      return this.weekReport?.text
    }
  }
}
</script>
