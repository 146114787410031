<template>
  <AppBar>

    <SchoolSubjectSelect
      :school-subjects="schoolSubjects"
      :value="filterBySchoolSubject"
      @input="$emit('input:filter-by-school-subject', $event)"
    />

    <span class="ml-2"></span>

    <ContextObjectSelect
      v-model="contextObject"
      :loading="loading"
      :filter-collections="['schoolClasses']"
    />

    <DateRangePicker
      v-if="!mini"
      v-model="dateInterval"
      :loading="loading"
      :allow-day="false"
      class="ml-4"
    />

  </AppBar>
</template>


<script>
import AppBar from '@/components/app-bar'
import SchoolSubjectSelect from '@/components/school-subject-select'
import ContextObjectSelect from '@/components/context-object-select'
import DateRangePicker from '@/components/date-range-picker'

export default {
  name: 'WeekReportsToolbarTop',
  components: {
    AppBar,
    SchoolSubjectSelect,
    ContextObjectSelect,
    DateRangePicker
  },
  props: {
    loading: Boolean,
    mini: Boolean,
    schoolSubjects: Array,
    filterBySchoolSubject: Object
  },
  computed: {
    contextObject: {
      get() {
        return this.$store.state.contextObject
      },
      set(contextObject) {
        this.$emit('input:context-object', contextObject)
      }
    },
    dateInterval: {
      get() {
        const { academicYear, section, startDate, endDate } = this.$store.state.schoolCalendar
        return {academicYear, section, startDate, endDate}
      },
      set(value) {
        this.$emit('input:date-interval', value)
      }
    }
  }
}
</script>
